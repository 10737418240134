import React, {useContext, useEffect} from 'react'
import ReactMarkdown from 'react-markdown'
import {Follow} from "react-twitter-widgets";
import {Seo} from "../../components/common";
import styled from "styled-components";
import {ThemeContext} from "../../providers/ThemeProvider";
import {StaticImage} from "gatsby-plugin-image";

const MainContentContainer = styled.div`
	background: ${({theme}) => (theme === 'light' ? '#fff' : 'transparent')};
	color: ${({theme}) => (theme === 'light' ? 'rgba(0,0,0,.84)' : 'rgb(230, 230, 230)')};

	h2 {
		border-bottom: ${({theme}) => (theme === 'light' ? '1px solid rgba(0,0,0,0.1)' : '1px solid rgba(255,255,255,0.5)')};
	}

	flex: 1;
	box-sizing: border-box;
	width: 100%;
	padding-bottom: 2em;
`

const AboutContainer = styled.main`
	background: ${({ theme }) => (theme === 'light' ? '#fff' : 'transparent')};
	max-width: 1200px;
	padding: 0 3%;
	margin: 0 auto;
	line-height: 1.58;
	letter-spacing: -0.003em;

	p {
			@media only screen and (max-width: 500px){
					padding-left: 15px;
					padding-right: 15px;
			}
	}
	font-size: 18px;

	p {
			margin-left: 0;
	}

	@media only screen and (max-width: 500px) {
			.pic-container {
					text-align: center;
			}
			.info-pic {
					float: none !important;
					margin: 0 !important;
					width: 50%;
					height: auto !important;
			}
	}
	@media only screen and (min-width: 800px) {
			& {
					display: grid;
					grid-template-columns: 1fr 1fr 1fr 1fr;
					grid-template-rows: auto;
					grid-column-gap: 3em;
			}
			
			.info {
					grid-column-start: 1;
					grid-column-end: 4;
			}
	}
	@media only screen and (max-width: 799px) {
			address *{
					text-align: center;
			}
	}

	.info-pic {
			height: 175px;
			float: left;
			margin: 6px 1.5em 0.8em 0;
	}

	p {
			@include justifyText;
			
			#lastname {
					font-size: 85%;
			}
			
			.italic {
					font-style: italic;
			}
	}

	.clear {
			clear: both;
	}

	.contact {
			list-style: none;
			padding: 0;
			font-family: 'SF Pro Text Regular', sans-serif;
			
			address {
					font-style: normal;
					margin-bottom: 2em;
					position: sticky;
					top: 1em;
			}
	}
`

const About = ({ lang, infoPic, about }) => {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, []);

	const { theme } = useContext(ThemeContext);
	const isEn = lang === "en"
	const { seoTitle, seoTitleCN, name, nameCN, details, detailsCN } = about
	const detailsContent = details.map((detail, idx) =>
		<ReactMarkdown
			className='detail-info bigger-first-letter'
			key={`detail${idx}`}
			children={detail}
			linkTarget="_blank"
		/>
	)
	const detailsContentCN = detailsCN.map((detail, idx) =>
		<ReactMarkdown
			className='detail-info bigger-first-letter'
			key={`detail${idx}`}
			children={detail}
			linkTarget="_blank"
		/>
	)

	return (
		<>
			{/*<Helmet htmlAttributes={{ lang : 'cn' }} />*/}
			<MainContentContainer theme={theme}>
				<AboutContainer theme={theme}>
					<Seo title={isEn ? seoTitle : seoTitleCN}
						description={isEn ? details.join(" ") : detailsCN.join(" ")}
						location={isEn ? "/about" : "/cn/about"}
						lang={lang}
					/>
					<div className='info'>
						<h2>{isEn ? `About ${name}` : nameCN}</h2>
						<div className='pic-container'>
							<StaticImage
								className='info-pic'
								src={"../../../assets/images/about.jpg"}
								alt={"Tao Jiang, Professor of Chinese Philosophy and Buddhist Philosophy at Rutgers University, New Brunswick."}
								placeholder="blurred"
								style={{ height: "100%", width: "100%", maxWidth: "200px" }}
								imgStyle={{ objectFit: "contain" }}
							/>
						</div>
						<div>
							{isEn ? detailsContent : detailsContentCN}
						</div>
						<div className='clear' />
					</div>
					<div className='contact'>
						<h2>{isEn ? "Contact Info" : "联系方式"}</h2>
						<address>
							<p><a href={'/CV_Tao_Jiang.pdf'} target="_blank"
										rel="noreferrer">{isEn ? "Curriculum Vitae" : "履历"}</a></p>
							<p>Rutgers University</p>
							<p>64 College Ave<br/>New Brunswick<br/>NJ 08901<br/>United States</p>
							<p><a href='mailto:tjiang@rutgers.edu'>tjiang@rutgers.edu</a></p>
							<Follow username="TaoJiangScholar" options={{dnt: true, showCount: false, size: "large"}}/>
						</address>
					</div>
				</AboutContainer>
			</MainContentContainer>
		</>
	)
}

export default About